html {
  background-color: #250f27;
  color: #eeeeee;
}

.wrapper {
  text-align: left;
  font-weight: 300;
  font: 1.2em sans-serif;
  margin: auto;
}

.body {
  padding: 12px 16px;
  margin: 5% auto;
  max-width: 960px;
  /* border: 1px dashed orange; */
}
@media (min-width:512px) {
  .body {
    padding: 16px 20px;
  }
}
@media (min-width:768px) {
  .body {
    padding: 18px 24px;
  }
}

.header {
  font: 1.5em monospace;
  letter-spacing: 0.2em;
  text-align: center;
  text-shadow: 0 0 10px #f700ff;
  /* border: 1px dashed pink; */
}

.title{
  padding: inherit;
  text-align: center;
  big{
    color: #ffffff;
    text-shadow: 0 0 10px rgb(22, 193, 178);
    font-size: 1.7em;
  }
  small{
    font-size: 0.6em;
  }
}

.content{
  padding: 20px;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 
    /* 0px 0px 2px rgb(255, 255, 255),
   -1px -1px 2px rgba(22, 193, 178, 0.75); */
   0px 0px 2px rgb(247, 0, 255),
   -1px -1px 2px rgba(255, 255, 255, 0.75);
   /* 3px 3px 3px #444444; */
   line-height: 1.3em;
   padding: 18px;
}

/* just brute-force overriding MUI */
.nav-button{
  color: #eeeeee !important;
  text-shadow: 0 0 10px #f700ff;
  -webkit-transition: all 333ms ease !important;
  -moz-transition: all 333ms ease !important;
  -o-transition: all 333ms ease !important;
  transition: all 333ms ease !important;
}
.nav-button:hover {
  color: #ffffff;
	text-shadow: 0 0 15px white, 0 0 10px rgba(22, 193, 178, 1);
	cursor: pointer;
}

/* input, textarea {
  margin: 15px 10px auto 10px;
  background-color: #830088;
  border: 1px inset #f700ff;
} */